import React from 'react'
import { MdDashboard, MdOutlineBusinessCenter, MdSportsScore } from "react-icons/md";
import { GiInspiration, GiPodiumWinner } from "react-icons/gi";
import { GrCurrency, GrWorkshop } from "react-icons/gr";
import { FcBusinessman } from "react-icons/fc";
import { BsQuestionSquare } from 'react-icons/bs';
import { BiCategoryAlt } from 'react-icons/bi';
import { HiOutlineUsers } from "react-icons/hi";

const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const Category = React.lazy(() => import("../pages/category/Category"));
const SkillVideo = React.lazy(() => import("../pages/skillVideo/SkillVideo"));
const SkillDocument = React.lazy(() => import("../pages/skillDocument/SkillDocument"));
const Country = React.lazy(() => import("../pages/country/Country"));
const Currency = React.lazy(() => import("../pages/currency/Currency"));
const Question = React.lazy(() => import("../pages/questions/Question"));
const Scoreboard = React.lazy(() => import("../pages/scoreboard/Scoreboard"));
const Winner = React.lazy(() => import("../pages/winner/Winner"));
const Subscriber = React.lazy(() => import("../pages/subscriber/Subscriber"));

export const adminRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/category",
        element: <Category />,
        name: "Category",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/skillDocument",
        element: <SkillDocument />,
        name: "Skill Document",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/skillVideo",
        element: <SkillVideo />,
        name: "Skill Video",
        icon: <MdOutlineBusinessCenter size={"1.5em"} />,
        sideBarVisible: true,
    },
    // {
    //     path: "/dashboard/country",
    //     element: <Country />,
    //     name: "Country",
    //     icon: <MdOutlineBusinessCenter size={"1.5em"} />,
    //     sideBarVisible: true,
    // },
    // {
    //     path: "/dashboard/currency",
    //     element: <Currency />,
    //     name: "Currency",
    //     icon: <GrCurrency size={"1.5em"} />,
    //     sideBarVisible: true
    // },
    {
        path: "/dashboard/questions",
        element: <Question />,
        name: "Questions",
        icon: <BsQuestionSquare size={"1.5em"} />,
        sideBarVisible: true
    },
    {
        path: "/dashboard/scoreboard",
        element: <Scoreboard />,
        name: "Scoreboard",
        icon: <MdSportsScore size={"1.5em"} />,
        sideBarVisible: true
    },
    {
        path: "/dashboard/winner",
        element: <Winner />,
        name: "Winners",
        icon: <GiPodiumWinner size={"1.5em"} />,
        sideBarVisible: true
    },
    // {
    //     path: "/subscribers",
    //     element: <Subscriber />,
    //     name: "Subscribers",
    //     icon: <HiOutlineUsers size={"1.5em"} />,
    //     sideBarVisible: true,
    // }
];
